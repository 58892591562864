import React, { useEffect, useState } from 'react';
import './home.scss';
import 'devextreme/dist/css/dx.light.css';
import PivotGrid, { FieldChooser, Export } from 'devextreme-react/pivot-grid';
import axios from 'axios'; // Import axios for making HTTP requests
import Modal from 'react-modal'; // Import modal dialog component

Modal.setAppElement('#root');

export default function Pivot() {
  const [dataSource, setDataSource] = useState(null);
  const [selectedTable, setSelectedTable] = useState('');
  const [tableOptions, setTableOptions] = useState([]);
  const [tableFields, setTableFields] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImRiX3VzZXIiLCJwYXNzd29yZCI6InB3ZF8xMjMiLCJleHAiOjI5NDkzODY3Nzd9.d3AwkaJTunayG6wC3pmGzLN4HFPMB02p57wjSbbggzk";

  const fetchTables = async () => {
    try {
      const response = await axios.get('https://med-b.ealamgroup.pk/customobject', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setTableOptions(response.data); // Set the list of tables
      console.log('Tables received:', response.data);
    } catch (error) {
      console.error('Error fetching tables:', error);
    }
  };

  useEffect(() => {
    if (modalIsOpen) {
      fetchTables();
    }
  }, [modalIsOpen, token]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://med-b.ealamgroup.pk/record/${selectedTable}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        console.log('Data received:', response.data); // Log the received data
        setDataSource(response.data);
        // Assuming the fields are dynamic and received from the API response
        setTableFields(response.data.fields || []); // Ensure tableFields is initialized even if no fields are received
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (selectedTable !== '') {
      fetchData();
    }
  }, [selectedTable, token]);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    console.log("Modal Closed"); // Corrected logging of modal closure
  };

  const handleTableSelection = (table) => {
    setSelectedTable(table);
    console.log(table)
    closeModal();
  };

  const onExporting = async (e) => {
    // Get the field configuration from the PivotGrid's data source
    const fields = e.component.getDataSource().fields();

    // Extract row, column, and data fields from the field configuration
    const rowFields = fields.filter(field => field.area === 'row').map(field => field.dataField);
    const columnFields = fields.filter(field => field.area === 'column').map(field => field.dataField);
    const dataFields = fields.filter(field => field.area === 'data').map(field => field.dataField);

    // Prepare the layout object to send to the backend
    let layoutData = {
      datastore: selectedTable, // Assuming selectedTable contains the table name
      rowfields: rowFields.join(','), // Convert array to comma-separated string
      columnfields: columnFields.join(','), // Convert array to comma-separated string
      datafields: dataFields.join(',') // Convert array to comma-separated string
    };

    try {
      // Check if data already exists in the table
      const checkResponse = await axios.get('https://med-b.ealamgroup.pk/customobject/reports', {
        params: layoutData,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

    } catch (error) {
      console.error('Error checking data existence:', error);
      // Optionally provide error feedback to the user
    }

    // Check if id is present, if not, generate a new id
    if (!layoutData.id) {
      layoutData.id = generateNewId(); // You need to define the function generateNewId() to generate a new id
    }

    try {
      // Send layout data to the backend API for saving
      const saveResponse = await axios.post('https://med-b.ealamgroup.pk/customobject/reports', layoutData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log('Layout saved:', saveResponse.data);
      // Optionally provide feedback to the user that the layout was successfully saved
    } catch (error) {
      console.error('Error saving layout:', error);
      // Optionally provide error feedback to the user
    }

    e.cancel = true; // Cancel the default exporting behavior
  };


  let lastGeneratedId = 0; // Initialize the last generated ID with the last used ID or any starting value

  function generateNewId() {
    // Increment the last generated ID and return it
    lastGeneratedId++;
    return lastGeneratedId;
  }


  if (!dataSource) {
    return (
      <div>
        <h2 className={'content-block'}>Home</h2>
        <div className={'content-block'}>
          <div className={'dx-card responsive-paddings'}>
            <button onClick={openModal}>Select Table</button>
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              contentLabel="Select Table Modal"
            >
              <h2>Select a Table</h2>
              <ul>
                {tableOptions.map((table, index) => (
                  <li key={index} onClick={() => handleTableSelection(table.name)}>{table.name}</li>
                ))}
              </ul>
              <button onClick={closeModal}>Close</button>
            </Modal>
          </div>
        </div>
      </div>
    );
  }

  const dataSourceConfig = {
    fields: tableFields.map(field => ({
      dataField: field, area: 'data' // Assuming each field is in the 'data' area
    })),
    store: {
      type: 'array',
      data: dataSource.data
    }
  };

  return (
    <div>
      <h2 className={'content-block'}>Pivot</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <button onClick={openModal}>Select Table</button>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Select Table Modal"
          >
            <h2>Select a Table</h2>
            <ul>
              {tableOptions.map((table, index) => (
                <li key={index} onClick={() => handleTableSelection(table.name)}>{table.name}</li>
              ))}
            </ul>
            <button onClick={closeModal}>Close</button>
          </Modal>
          <PivotGrid
            id="pivotGrid"
            dataSource={dataSourceConfig}
            allowSortingBySummary={true}
            allowFiltering={true}
            allowSorting={true}
            height={470}
            showBorders={true}
            onExporting={onExporting} // Attach the onExporting event handler
          >
            <FieldChooser enabled={true} allowSearch={true} />
            <Export enabled={true} /> {/* Export enable in pivotGrid*/}
          </PivotGrid>

        </div>
      </div>
    </div>
  );
}
