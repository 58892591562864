import { BASE_URL } from '../utils/default-user';

export async function signIn(username, password) {
  try {
    const loginEndpoint = `${BASE_URL}login`;
    const response = await fetch(loginEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    });

    const data = await response.json();

    if (data.status === "success") {
      localStorage.setItem("token", data.token); // Save token in localStorage
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: "Invalid username or password",
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Authentication failed",
    };
  }
}


export async function createAccount(email, phone, username, password) {
  try {
    const registerEndpoint = `${BASE_URL}register`;
    const registrationData = { email, phone, username, password };

    const response = await makeApiRequest(registerEndpoint, 'POST', registrationData);

    if (response && response.message === 'User registered successfully') {
      return {
        isOk: true,
      };
    } else {
      return {
        isOk: false,
        message: "Failed to create account",
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Failed to create account",
    };
  }
}
async function makeApiRequest(url, method, data) {
  try {
    const response = await fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    return await response.json();
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
}


export async function changePassword(email, recoveryCode) {
  try {
    const changePasswordEndpoint = `${BASE_URL}change-password`;
    const response = await fetch(changePasswordEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, recoveryCode }),
    });

    const data = await response.json();

    if (data.status === "success") {
      return {
        isOk: true,
      };
    } else {
      return {
        isOk: false,
        message: "Failed to change password",
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Failed to change password",
    };
  }
}

export async function resetPassword(email) {
  try {
    const resetPasswordEndpoint = `${BASE_URL}reset-password`;
    const response = await fetch(resetPasswordEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });

    const data = await response.json();

    if (data.status === "success") {
      return {
        isOk: true,
      };
    } else {
      return {
        isOk: false,
        message: "Failed to reset password",
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Failed to reset password",
    };
  }
}
export async function getUser() {
  try {
    const getUserEndpoint = `${BASE_URL}get-user`;
    const response = await fetch(getUserEndpoint, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      },
    });

    const data = await response.json();

    if (response.ok) {
      return {
        isOk: true,
        data: data.userInfo,
      };
    } else {
      return {
        isOk: false,
        message: "Failed to fetch user info",
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Failed to fetch user info",
    };
  }
}