import React, { useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DataGrid, { Editing, Scrolling, SearchPanel, Export, ColumnChooser, Selection, HeaderFilter } from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react/data-grid';
import { Column, Paging, Pager } from 'devextreme-react/data-grid';
import { exportDataGrid } from 'devextreme/pdf_exporter';
import { jsPDF } from 'jspdf';
import { Workbook } from 'exceljs';
import { Button } from 'devextreme-react/button';
import { Form } from 'devextreme-react/form';

import { Toolbar, Item as ToolbarItem } from 'devextreme-react/toolbar';
import { DropDownButton, Item as DropDownItem } from 'devextreme-react/drop-down-button';
import { TabPanel, Item as TabPanelItem } from 'devextreme-react/tab-panel';
import { ValidationGroup } from 'devextreme-react/validation-group';
import ScrollView from 'devextreme-react/scroll-view';
import 'devextreme/dist/css/dx.material.blue.light.compact.css';
import './tasks.css'
import './task.scss'


const colCountByScreen = {
  sm: 1,
  md: 1,
};
const exportFormats = ['pdf', 'xlsx'];

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Tasks = ({ label, name }) => {
  const [columns, setColumns] = useState([]);
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentLabel, setLabel] = useState('');
  const [row_id, setRowId] = useState(null);
  const [showDetail, setShowDetail] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const query = useQuery();
  const tab_name = query.get('tab');

  const [calculateColCountAutomatically, setCalculateColCountAutomatically] = useState(false);
  const onCalculateColCountAutomaticallyChanged = useCallback(
    (e) => {
      setCalculateColCountAutomatically(e.value);
    },
    [setCalculateColCountAutomatically],
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("Token not found in localStorage");
        }

        const response = await fetch("https://med-b.ealamgroup.pk/record/" + tab_name, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        setColumns(Object.keys(data.data[0]).map(key => ({
          dataField: key,
          caption: key.charAt(0).toUpperCase() + key.slice(1),
          allowReordering: true,
          allowHiding: true,
        })));
        setRecords(data.data);
        setLoading(false);
        setLabel(label);
      } catch (error) {
        console.error("Fetch error:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, [label, name]);
  const onRowClick = (e) => {
    setSelectedRow(e.data);
    setShowDetail(true);
  };
  const onExporting = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    const doc = new jsPDF();
    exportDataGrid({
      jsPDFDocument: doc,
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'pg_user.xlsx';
        a.click();
        window.URL.revokeObjectURL(url);
      });
      doc.save('pg_user.pdf');
    });
  };
  const onRowInserted = async (e) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found in localStorage");
      }

      const response = await fetch(`https://med-b.ealamgroup.pk/customobject/${name}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(e.data),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const newData = await response.json();
      setRecords([...records, newData]);
    } catch (error) {
      console.error("Insert error:", error);
    }
  };
  const onRowUpdated = async (e) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found in localStorage");
      }

      const { id, column_name, new_value } = e.data;

      const response = await fetch(`https://med-b.ealamgroup.pk/customobject/${name}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id, column_name, new_value }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const updatedRecords = records.map(record =>
        record.id === id ? { ...record, [column_name]: new_value } : record
      );
      setRecords(updatedRecords);
    } catch (error) {
      console.error("Update error:", error);
    }
  };
  const onRowRemoved = async (e) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found in localStorage");
      }

      const response = await fetch(`https://med-b.ealamgroup.pk/customobject/${name}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ row_id: e.data.id }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const updatedRecords = records.filter(record =>
        record.id !== e.data.id
      );
      setRecords(updatedRecords);
    } catch (error) {
      console.error("Delete error:", error);
    }
  };


  const handleEdit = () => {
    // Handle edit functionality here
  };

  const handleActivityOption = () => {
    // Handle activity option functionality here
  };

  const handleNoteOption = () => {
    // Handle note option functionality here
  };

  const handleMessageOption = () => {
    // Handle message option functionality here
  };
  return (
    <div>
      <h2 style={{ color: '#03a9f4', fontSize: '40px', paddingTop: '0px', paddingLeft: '15px' }}>{currentLabel}</h2>
      {!showDetail && (
        <DataGrid
          id="gridContainer"
          dataSource={records}
          showBorders={true}
          onExporting={onExporting}
          allowColumnReordering={true}
          allowColumnResizing={true}
          adaptivityEnabled={true}
          columnAutoWidth={true}
          wordWrapEnabled={true}
          onRowInserted={onRowInserted}
          onRowUpdated={onRowUpdated}
          onRowRemoved={onRowRemoved}
          onRowClick={onRowClick}
          columnHidingEnabled={true}
          columnMinWidth={50}
          cellHintEnabled={true}
        >
          {columns.map((column) => (

            <Column
              key={column.dataField}
              dataField={column.dataField}
              caption={column.caption}
              allowReordering={column.allowReordering}
              allowHiding={column.allowHiding}
              editorOptions={column.editorOptions}
            />
          ))}
          <Editing mode="popup" allowUpdating={true} allowAdding={true} allowDeleting={true}>
            <Popup title="Edit User" showTitle={true} width={500} height={300} />
          </Editing>
          <Paging defaultPageSize={5} />
          <Pager
            visible={true}
            showPageSizeSelector={true}
            allowedPageSizes={[5, 10, 'all']}
          />
          <Scrolling mode="virtual" />
          <SearchPanel visible={true} width={250} placeholder="Search..." />
          <Export
            enabled={true}
            formats={exportFormats}
            allowExportSelectedData={true}
          />
          <ColumnChooser enabled={true} mode="select" />
          <Selection mode="multiple" />
          <HeaderFilter visible={true} />
        </DataGrid>
      )}
      {showDetail && selectedRow && (
      <div className={'content-block dx-card responsive-paddings'}>
      <ScrollView className='view-wrapper-scroll'>
        <div className='view-wrapper view-wrapper-details'>
          <Toolbar className='toolbar-details theme-dependent'>
            <ToolbarItem location='before'>
              <Button icon='arrowleft' stylingMode='text' onClick={() => setShowDetail(false)} />
              <Button text='Edit' icon='edit' stylingMode='text' onClick={handleEdit} />
            </ToolbarItem>
            <ToolbarItem location='after' locateInMenu='auto'>
              <DropDownButton text='Actions' stylingMode='text' dropDownOptions={{ width: 'auto' }}>
                <DropDownItem text='Duplicate' />
                <DropDownItem text='Close' />
                <DropDownItem text='Delete' />
              </DropDownButton>
            </ToolbarItem>
            <ToolbarItem location='after' locateInMenu='auto'>
              <Button text='Attach' icon='attach' stylingMode='text' />
            </ToolbarItem>
            <ToolbarItem location='after' locateInMenu='auto'>
              <Button text='Refresh' icon='refresh' stylingMode='text' />
            </ToolbarItem>
          </Toolbar>
          <div className='panels'>
            <div className='left'>
              <ValidationGroup>
              <div id="form-demo">
            <div className={'content-block1 dx-card responsive-paddings'}>
              <Form
                id="form"
                formData={selectedRow}
                colCountByScreen={calculateColCountAutomatically ? true : colCountByScreen}
                minColWidth={233}
                readOnly={true}
                showColonAfterLabel={true}
                labelLocation="left"
                colCount={1}
                screenByWidth={screenByWidth}
              />
            </div>
        </div>
              </ValidationGroup>
            </div>
            <div className='right'>
              <div className='dx-card details-card'>
                <TabPanel showNavButtons focusStateEnabled={false} deferRendering={false}>
                  <TabPanelItem title='Activities'>
                    <Button text='Option' stylingMode='text' onClick={handleActivityOption} />
                    {/* Activities component here */}
                  </TabPanelItem>
                  <TabPanelItem title='Notes'>
                    <Button text='Option' stylingMode='text' onClick={handleNoteOption} />
                    {/* Notes component here */}
                  </TabPanelItem>
                  <TabPanelItem title='Messages'>
                    <Button text='Option' stylingMode='text' onClick={handleMessageOption} />
                    {/* Messages component here */}
                  </TabPanelItem>
                </TabPanel>
              </div>
            </div>
          </div>
        </div>
      </ScrollView>
      </div>
       )}
    </div>
  );
};
function screenByWidth(width) {
  return width < 920 ? 'sm' : 'md';
}
export default Tasks;
