// import { HomePage, TasksPage, ProfilePage } from './pages';
// import { withNavigationWatcher } from './contexts/navigation';

// const routes = [
//     {
//         path: '/tasks',
//         element: TasksPage,
//     },
//     {
//         path: '/profile',
//         element: ProfilePage
//     },
//     {
//         path: '/home',
//         element: HomePage
//     }
// ];

// export default routes.map(route => {
//     return {
//         ...route,
//         element: withNavigationWatcher(route.element, route.path)
//     };
// });

import Home from "./pages/home/Home";
import Html from "./pages/html-editor/Html";
import Profile from "./pages/profile/Profile";
import Tasks from "./pages/tasks/tasks";
import { withNavigationWatcher } from "./contexts/navigation";
import Pivot from "./pages/pivot/Pivot";



let routes = [
  { path: "/Profile", element: <Profile /> },
  { path: "/Home", element: <Home /> },
  { path: "/Pivot", element: <Pivot /> },
  { path: "/Html", element: <Html /> },
  { path: "/Tasks", element: <Tasks /> },
];

export default routes;
