// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logos-container {
  margin: 20px 0 40px 0;
  text-align: center;
}
.logos-container svg {
  display: inline-block;
}

.devextreme-logo {
  width: 200px;
  height: 34px;
  margin-bottom: 19px;
}

.react-logo {
  width: 184px;
  height: 68px;
}

.plus {
  margin: 23px 10px;
  width: 22px;
  height: 22px;
}

.screen-x-small .logos-container svg {
  width: 100%;
  display: block;
}
.screen-x-small .logos-container svg.plus {
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/pivot/home.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,kBAAA;AACF;AAAE;EACE,qBAAA;AAEJ;;AAEA;EACE,YAAA;EACA,YAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;EACA,YAAA;AACF;;AAEA;EACE,iBAAA;EACA,WAAA;EACA,YAAA;AACF;;AAGE;EACE,WAAA;EACA,cAAA;AAAJ;AACI;EACE,SAAA;AACN","sourcesContent":[".logos-container {\n  margin: 20px 0 40px 0;\n  text-align: center;\n  svg {\n    display: inline-block;\n  }\n}\n\n.devextreme-logo {\n  width: 200px;\n  height: 34px;\n  margin-bottom: 19px;\n}\n\n.react-logo {\n  width: 184px;\n  height: 68px;\n}\n\n.plus {\n  margin: 23px 10px;\n  width: 22px;\n  height: 22px;\n}\n\n.screen-x-small .logos-container {\n  svg {\n    width: 100%;\n    display: block;\n    &.plus {\n      margin: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
