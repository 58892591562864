// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.widget-container {
    margin-right: 320px;
    padding: 20px;
    max-width: 550px;
    min-width: 300px;
  }
  
  #form {
    margin-top: 25px;
  }
  
  .options {
    padding: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 260px;
    top: 0;
    background-color: rgba(191, 191, 191, 0.15);
  }
  
  .caption {
    font-size: 18px;
    font-weight: 500;
  }
  
  .option {
    margin-top: 10px;
  }

  .dx-texteditor.dx-editor-filled.dx-state-disabled .dx-texteditor-input, .dx-texteditor.dx-editor-filled.dx-state-readonly .dx-texteditor-input, .dx-texteditor.dx-editor-filled.dx-state-readonly.dx-state-hover .dx-texteditor-input {
    color: rgb(0 0 0);
}`, "",{"version":3,"sources":["webpack://./src/pages/tasks/tasks.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,gBAAgB;IAChB,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,YAAY;IACZ,MAAM;IACN,2CAA2C;EAC7C;;EAEA;IACE,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,iBAAiB;AACrB","sourcesContent":[".widget-container {\n    margin-right: 320px;\n    padding: 20px;\n    max-width: 550px;\n    min-width: 300px;\n  }\n  \n  #form {\n    margin-top: 25px;\n  }\n  \n  .options {\n    padding: 20px;\n    position: absolute;\n    bottom: 0;\n    right: 0;\n    width: 260px;\n    top: 0;\n    background-color: rgba(191, 191, 191, 0.15);\n  }\n  \n  .caption {\n    font-size: 18px;\n    font-weight: 500;\n  }\n  \n  .option {\n    margin-top: 10px;\n  }\n\n  .dx-texteditor.dx-editor-filled.dx-state-disabled .dx-texteditor-input, .dx-texteditor.dx-editor-filled.dx-state-readonly .dx-texteditor-input, .dx-texteditor.dx-editor-filled.dx-state-readonly.dx-state-hover .dx-texteditor-input {\n    color: rgb(0 0 0);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
