// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbar-details {
  margin-bottom: calc(var(--toolbar-margin-bottom) / 2);
}

.view-wrapper.view-wrapper-details {
  position: relative;
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  padding-top: var(--content-padding);
  padding-bottom: var(--content-padding);
}
.view-wrapper.view-wrapper-details .panels {
  display: flex;
  gap: 0px;
  justify-content: space-between;
  align-items: flex-start;
}
.view-wrapper.view-wrapper-details .panels .left {
  flex: 1 1 400px;
  margin-top: 8px;
}
.view-wrapper.view-wrapper-details .panels .right {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(100% - 400px - 20px);
  margin-top: 8px;
  min-width: 340px;
}`, "",{"version":3,"sources":["webpack://./src/pages/tasks/task.scss"],"names":[],"mappings":"AAAA;EACE,qDAAA;AACF;;AAEA;EACE,kBAAA;EACA,sBAAA;EACA,aAAA;EACA,YAAA;EACA,mCAAA;EACA,sCAAA;AACF;AACE;EACE,aAAA;EACA,QAAA;EACA,8BAAA;EACA,uBAAA;AACJ;AACI;EACE,eAAA;EACA,eAAA;AACN;AAEI;EACE,YAAA;EAAA,cAAA;EAAA,qCAAA;EACA,eAAA;EACA,gBAAA;AAAN","sourcesContent":[".toolbar-details {\n  margin-bottom: calc(var(--toolbar-margin-bottom) / 2);\n}\n\n.view-wrapper.view-wrapper-details {\n  position: relative;\n  flex-direction: column;\n  display: flex;\n  flex-grow: 1;\n  padding-top: var(--content-padding);\n  padding-bottom: var(--content-padding);\n\n  .panels {\n    display: flex;\n    gap: 0px;\n    justify-content: space-between;\n    align-items: flex-start;\n\n    .left {\n      flex: 1 400px;\n      margin-top: 8px;\n    }\n\n    .right {\n      flex: 1 calc(100% - 400px - 20px);\n      margin-top: 8px;\n      min-width: 340px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
