// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side-nav-outer-toolbar {
  flex-direction: column;
  display: flex;
  height: 100%;
  width: 100%;
}

.layout-header {
  z-index: 1501;
}

.layout-body {
  background-color: var(--background-color);
}

.drawer {
  flex: 1 1;
  min-height: 0;
}

.content {
  flex-grow: 1;
}`, "",{"version":3,"sources":["webpack://./src/layouts/side-nav-inner-toolbar/side-nav-inner-toolbar.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,aAAA;EACA,YAAA;EACA,WAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,yCAAA;AACF;;AAEA;EACE,SAAA;EACA,aAAA;AACF;;AAEA;EACE,YAAA;AACF","sourcesContent":[".side-nav-outer-toolbar {\n  flex-direction: column;\n  display: flex;\n  height: 100%;\n  width: 100%;\n}\n\n.layout-header {\n  z-index: 1501;\n}\n\n.layout-body {\n  background-color: var(--background-color);\n}\n\n.drawer {\n  flex: 1;\n  min-height: 0;\n}\n\n.content {\n  flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
