import { useState, useEffect } from 'react';

function Navigation() {
  const [navigation, setNavigation] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://med-b.ealamgroup.pk/record/apptab', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (!response.ok) {
          if (response.status === 403) {
            throw new Error("Permission denied. You don't have access.");
          } else {
            throw new Error(`Permission denied. You don't have access: ${response.statusText}`);
          }
        }

        const data = await response.json();
        const mappedData = data.data.map(item => ({
          text: item.label,
          path: `/Tasks?tab=${item.name}`,
          icon: item.icon,
        }));
        
        const staticItems = [
          { text: 'Profile', path: '/profile', icon: 'user' },
          { text: 'Home', path: '/home', icon: 'home' },
          { text: 'Pivot', path: '/pivot', icon: 'dataarea' },
          { text: 'Page Builder', path: '/Html', icon: 'dataarea' },
        ];

        setNavigation([...staticItems, ...mappedData]);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  return navigation;
}

export { Navigation };
